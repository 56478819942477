import React from "react";
import { graphql,  useStaticQuery } from "gatsby";
import Image from "gatsby-image";
import {
  useIntl,Link
} from "gatsby-plugin-react-intl";


const NotFoundImageBanner = (props) => {
  const {
    allContentfulMainSlider: { nodes: notFoundImage },
  } = useStaticQuery(query);
  const intl = useIntl();
  const notFoundPageImage = intl.locale === "en" ? notFoundImage[0].notFoundPageImage.fluid : notFoundImage[0].notFoundPageImageFr.fluid || notFoundImage[0].notFoundPageImage.fluid ;
  return (
    <div className="courses-details-area not-found">
      <div className="courses-details-image">
        <Image fluid={notFoundPageImage} alt="Medart-notFoundImage Banner" />
        <Link to="/" className="default-btn text-center mt-5 m-auto ">
            <i className="flaticon-right"></i> 
            Goto {intl.formatMessage({ id: "home" })} 
        </Link>
      </div>
    </div>
  );
};

export const query = graphql`
  {
    allContentfulMainSlider {
      nodes {
        notFoundPageImage {
          fluid(quality: 100)  {
             ...GatsbyContentfulFluid
          }
        }
        notFoundPageImageFr {
          fluid(quality: 100)  {
             ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
;

export default NotFoundImageBanner;
