import React from "react";
import Layout from "../components/App/Layout";
import NavbarTwo from "../components/App/NavbarTwo";
import Footer from "../components/App/Footer";
import NotFoundBanner from "../components/404/NotFoundBanner";

const FAQ = () => {
  return (
    <Layout pageNotFound>
      <NavbarTwo />
      <NotFoundBanner />
      <Footer />
    </Layout>
  );
};

export default FAQ;
